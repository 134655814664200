import React from 'react';
import Container from './container';
import styles from './cta-section.module.scss';

const CtaSection = ({ text, children, variant }) => {
  return (
    <div className={variant === 'ctaButtonColor' ? styles.bgColor : ''}>
      <Container>
        <div className={variant === 'ctaButtonColor' ? styles.ctaColor : styles.cta}>
          <p>{text}</p>
          <div className={styles.button}>{children}</div>
        </div>
      </Container>
    </div>
  );
};

export default CtaSection;
