import React from 'react';
import AssetImg from '../asset/asset-img';
import styles from './iconlist.module.scss';
import clsx from 'clsx';

export const IconList = ({ items, className, ...other }) => {
  return (
    <ul {...other} className={clsx(styles.iconList, className)}>
      {items.map(({ icon, asset, text, altText }, idx) => (
        <li key={`icon-${idx}`}>
          <div>
            <AssetImg asset={asset} alt={altText} />
          </div>
          <span>{text}</span>
        </li>
      ))}
    </ul>
  );
};
