import React from 'react';
import CtaSection from './cta-section';
import { InternalLinkButton } from '../button/internal-link-button';
import styles from './cta-1to1-meeting.module.scss';

const Cta1to1Meeting = ({ text, btnText, link, variant }) => {
  return (
    <CtaSection variant={variant} text={text}>
      <InternalLinkButton
        to={link ? link : '/'}
        className={variant === 'ctaButtonColor' ? styles.ctaButtonWhite : styles.ctaButton}
      >
        {btnText}
      </InternalLinkButton>
    </CtaSection>
  );
};

export default Cta1to1Meeting;
